var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), !_vm.isLoading ? _c('div', [_c('validation-observer', {
    ref: "observer"
  }, [_c('b-form', {
    attrs: {
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSubmit($event);
      }
    }
  }, [_c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Add team")]), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "team-name"
    }
  }, [_vm._v("Name")]), _c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var componentErrors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "autofocus": "",
            "name": "name",
            "placeholder": "Team Epic"
          },
          model: {
            value: _vm.team.name,
            callback: function callback($$v) {
              _vm.$set(_vm.team, "name", $$v);
            },
            expression: "team.name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }], null, false, 466541026)
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(" Email (not required, displayed to public) ")]), _c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "name": "email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.team.email,
            callback: function callback($$v) {
              _vm.$set(_vm.team, "email", $$v);
            },
            expression: "team.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }], null, false, 3044766225)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Team avatar & games")]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "team-avatar mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.team.avatar_image,
      "size": "100px",
      "square": ""
    },
    on: {
      "click": _vm.openAvatarFilePopup
    }
  })], 1), _c('div', {
    staticClass: "team-avatar-upload-container"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap justify-content-around"
  }, [_c('b-button', {
    staticClass: "w-100 mb-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.openAvatarFilePopup
    }
  }, [_vm._v(" Upload ")]), _c('b-button', {
    staticClass: "w-100",
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.clearAvatar
    }
  }, [_vm._v(" Remove ")])], 1), _c('label', [_vm._v("Allowed JPG, GIF or PNG. Max size of 1MB")]), _c('b-form-file', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "avatarFileInput",
    staticClass: "mt-1",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.avatar,
      callback: function callback($$v) {
        _vm.avatar = $$v;
      },
      expression: "avatar"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "games"
    }
  }, [_vm._v("Select your games")]), _c('validation-provider', {
    attrs: {
      "name": "games",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "games",
            "options": _vm.disciplinesOptions,
            "reduce": function reduce(discipline) {
              return discipline.code;
            },
            "multiple": "",
            "name": "games"
          },
          model: {
            value: _vm.team.disciplines,
            callback: function callback($$v) {
              _vm.$set(_vm.team, "disciplines", $$v);
            },
            expression: "team.disciplines"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }], null, false, 909019436)
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Social media")]), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "discord"
    }
  }, [_vm._v("Discord")]), _c('b-form-input', {
    attrs: {
      "name": "discord",
      "placeholder": "Enter discord link"
    },
    model: {
      value: _vm.team.socialLinks.discord.url,
      callback: function callback($$v) {
        _vm.$set(_vm.team.socialLinks.discord, "url", $$v);
      },
      expression: "team.socialLinks.discord.url"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "instagram"
    }
  }, [_vm._v("Instagram")]), _c('b-form-input', {
    attrs: {
      "name": "instagram",
      "placeholder": "Enter instagram link"
    },
    model: {
      value: _vm.team.socialLinks.instagram.url,
      callback: function callback($$v) {
        _vm.$set(_vm.team.socialLinks.instagram, "url", $$v);
      },
      expression: "team.socialLinks.instagram.url"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "twitter"
    }
  }, [_vm._v("Twitter")]), _c('b-form-input', {
    attrs: {
      "name": "twitter",
      "placeholder": "Enter facebook link"
    },
    model: {
      value: _vm.team.socialLinks.twitter.url,
      callback: function callback($$v) {
        _vm.$set(_vm.team.socialLinks.twitter, "url", $$v);
      },
      expression: "team.socialLinks.twitter.url"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "facebook"
    }
  }, [_vm._v("Facebook")]), _c('b-form-input', {
    attrs: {
      "name": "facebook",
      "placeholder": "Enter facebook link"
    },
    model: {
      value: _vm.team.socialLinks.facebook.url,
      callback: function callback($$v) {
        _vm.$set(_vm.team.socialLinks.facebook, "url", $$v);
      },
      expression: "team.socialLinks.facebook.url"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('dismissible-message-box', {
    staticClass: "p-1 w-100",
    attrs: {
      "show": _vm.showError,
      "variant": _vm.MessageType.ERROR
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _c('dismissible-message-box', {
    staticClass: "p-1 w-100",
    attrs: {
      "show": _vm.showSuccess,
      "variant": _vm.MessageType.SUCCESS
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" Team created! Press back to go back to your profile where you can see your new team. ")], 1)], 1), _c('b-row', {
    staticClass: "justify-content-end"
  }, [_c('spinning-button', {
    staticClass: "mr-3",
    attrs: {
      "is-loading": _vm.isSaving,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Save ")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleCancelClick
    }
  }, [_vm._v(" Back ")])], 1)], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }