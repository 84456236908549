<template>
  <b-card
    no-body
    class="p-2"
  >
    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </div>

    <div v-if="!isLoading">
      <validation-observer ref="observer">
        <b-form
          enctype="multipart/form-data"
          @submit.stop.prevent="handleSubmit"
        >
          <b-row class="d-flex justify-space-around">
            <b-col md="4">
              <h5>Add team</h5>

              <b-form-group>
                <label for="team-name">Name</label>
                <validation-provider
                  v-slot="{ errors: componentErrors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="team.name"
                    autofocus
                    name="name"
                    placeholder="Team Epic"
                  />

                  <small class="text-danger">{{ componentErrors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="email">
                  Email (not required, displayed to public)
                </label>
                <validation-provider
                  v-slot="{ errors: componentErrors }"
                  name="email"
                  rules="email"
                >
                  <b-form-input
                    v-model="team.email"
                    name="email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ componentErrors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>

            <b-col md="4">
              <h5>Team avatar & games</h5>
              <!-- Avatar Upload -->
              <div class="d-flex justify-content-between">
                <div class="team-avatar mr-1">
                  <b-avatar
                    :src="team.avatar_image"
                    size="100px"
                    square
                    @click="openAvatarFilePopup"
                  />
                </div>
                <div class="team-avatar-upload-container">
                  <div class="d-flex flex-wrap justify-content-around">
                    <b-button
                      class="w-100 mb-1"
                      size="sm"
                      variant="primary"
                      @click="openAvatarFilePopup"
                    >
                      Upload
                    </b-button>

                    <b-button
                      class="w-100"
                      size="sm"
                      variant="outline-primary"
                      @click="clearAvatar"
                    >
                      Remove
                    </b-button>
                  </div>

                  <label>Allowed JPG, GIF or PNG. Max size of 1MB</label>
                  <b-form-file
                    v-show="false"
                    ref="avatarFileInput"
                    v-model="avatar"
                    class="mt-1"
                    plain
                  />
                </div>
              </div>
              <!--/ Avatar Upload -->

              <div class="mt-1">
                <b-form-group>
                  <label for="games">Select your games</label>
                  <validation-provider
                    v-slot="{ errors: componentErrors }"
                    name="games"
                    rules="required"
                  >
                    <v-select
                      id="games"
                      v-model="team.disciplines"
                      :options="disciplinesOptions"
                      :reduce="discipline => discipline.code"
                      multiple
                      name="games"
                    />
                    <small class="text-danger">{{ componentErrors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>

            <b-col md="4">
              <!-- Social Media Input -->
              <h5>Social media</h5>
              <b-form-group>
                <label for="discord">Discord</label>

                <b-form-input
                  v-model="team.socialLinks.discord.url"
                  name="discord"
                  placeholder="Enter discord link"
                />
              </b-form-group>

              <b-form-group>
                <label for="instagram">Instagram</label>
                <b-form-input
                  v-model="team.socialLinks.instagram.url"
                  name="instagram"
                  placeholder="Enter instagram link"
                />
              </b-form-group>

              <!-- Twitter -->
              <b-form-group>
                <label for="twitter">Twitter</label>

                <b-form-input
                  v-model="team.socialLinks.twitter.url"
                  name="twitter"
                  placeholder="Enter facebook link"
                />
              </b-form-group>

              <!-- Facebook -->
              <b-form-group>
                <label for="facebook">Facebook</label>

                <b-form-input
                  v-model="team.socialLinks.facebook.url"
                  name="facebook"
                  placeholder="Enter facebook link"
                />
              </b-form-group>
            <!--/ Social Media Input  -->
            </b-col>
          </b-row>

          <b-row>
            <dismissible-message-box
              :show="showError"
              :variant="MessageType.ERROR"
              class="p-1 w-100"
            >
              <feather-icon
                class="mr-50"
                icon="InfoIcon"
              />
              {{ errors[0] }}
            </dismissible-message-box>

            <dismissible-message-box
              :show="showSuccess"
              :variant="MessageType.SUCCESS"
              class="p-1 w-100"
            >
              <feather-icon
                class="mr-50"
                icon="InfoIcon"
              />
              Team created! Press back to go back to your profile where you can see your new team.
            </dismissible-message-box>
          </b-row>

          <b-row class="justify-content-end">
            <spinning-button
              :is-loading="isSaving"
              type="submit"
              variant="primary"
              class="mr-3"
            >
              Save
            </spinning-button>

            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="handleCancelClick"
            >
              Back
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCol,
  BCard,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import LinkType from '@/constants/LinkType'
import LinkEntityType from '@/constants/LinkEntityType'
import SpinningButton from '@/components/SpinningButton.vue'
import MessageType from '@/constants/MessageType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import Disciplines from '@/constants/Disciplines'

export default {
  components: {
    DismissibleMessageBox,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BAvatar,
    vSelect,
    BCard,
    SpinningButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MessageType,
      disciplinesOptions: Disciplines.map(discipline => ({
        label: discipline.title,
        code: discipline.id,
      })),
      isLoading: false,
      isSaving: false,
      team: {
        id: Number(this.$route.params.id),
        ownerId: null,
        email: null,
        name: null,
        avatar_image: null,
        disciplines: [],
        socialLinks: {
          facebook: {
            id: null,
            url: null,
            link_type_id: LinkType.FACEBOOK,
          },
          twitter: {
            id: null,
            url: null,
            link_type_id: LinkType.TWITTER,
          },
          instagram: {
            id: null,
            url: null,
            link_type_id: LinkType.INSTAGRAM,
          },
          discord: {
            id: null,
            url: null,
            link_type_id: LinkType.DISCORD,
          },
        },
      },
      avatar: null,
      errors: [],
      showError: false,
      showSuccess: false,
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },

  },
  watch: {
    avatar(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then(value => {
              this.team.avatar_image = value
            })
            .catch(() => {
              this.team.avatar_image = null
            })
        } else {
          this.team.avatar_image = null
        }
      }
    },
  },
  methods: {
    handleCancelClick() {
      this.$store.dispatch('user/fetchMe')

      this.$router.go(-1)
    },
    async clearAvatar() {
      this.errors = []
      this.showError = false
      this.showSuccess = false

      const { success } = await this.$api.team.removeAvatar(this.id)
      if (!success) {
        this.errors = 'Something went wrong!'

        return
      }

      this.avatar = null
      this.team.avatar_image = null
    },
    openAvatarFilePopup() {
      this.$refs.avatarFileInput.$el.click()
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handleSubmit() {
      this.errors = []
      this.showError = false
      this.showSuccess = false

      const formValid = await this.$refs.observer.validate()
      if (!formValid) {
        return
      }

      const payload = {
        owner_id: this.me.id,
        name: this.team.name,
        email: this.team.email,
        avatar: this.avatar,
        disciplines: this.team.disciplines,
        links: [],
      }

      if (this.team.socialLinks.facebook?.url) {
        payload.links.push({
          url: this.team.socialLinks.facebook.url,
          link_type_id: this.team.socialLinks.facebook.link_type_id,
          link_entity_type: LinkEntityType.TEAM,
        })
      }

      if (this.team.socialLinks.twitter?.url) {
        payload.links.push({
          url: this.team.socialLinks.twitter.url,
          link_type_id: this.team.socialLinks.twitter.link_type_id,
          link_entity_type: LinkEntityType.TEAM,
        })
      }

      if (this.team.socialLinks.discord?.url) {
        payload.links.push({
          url: this.team.socialLinks.discord.url,
          link_type_id: this.team.socialLinks.discord.link_type_id,
          link_entity_type: LinkEntityType.TEAM,
        })
      }

      if (this.team.socialLinks.instagram?.url) {
        payload.links.push({
          url: this.team.socialLinks.instagram.url,
          link_type_id: this.team.socialLinks.instagram.link_type_id,
          link_entity_type: LinkEntityType.TEAM,
        })
      }

      this.isSaving = true

      const { errors } = await this.$api.team.createTeam(payload)

      this.isSaving = false

      if (errors) {
        if (errors[0].extensions.code === 'DATA_CONFLICT_ERROR') {
          this.errors.push('Team with this name already exists!')
        }
        this.errors.push('Something went wrong. Please try again or contact our support')

        this.showError = true

        return
      }

      await this.$store.dispatch('user/fetchMe')

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false

        this.$router.go(-1)
      }, 4000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
